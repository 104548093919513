exports.components = {
  "component---src-components-docs-template-js": () => import("./../../../src/components/docs-template.js" /* webpackChunkName: "component---src-components-docs-template-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-general-guide-docs-js": () => import("./../../../src/pages/general-guide-docs.js" /* webpackChunkName: "component---src-pages-general-guide-docs-js" */),
  "component---src-pages-gitlab-docs-js": () => import("./../../../src/pages/gitlab-docs.js" /* webpackChunkName: "component---src-pages-gitlab-docs-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-react-guide-docs-js": () => import("./../../../src/pages/react-guide-docs.js" /* webpackChunkName: "component---src-pages-react-guide-docs-js" */),
  "component---src-pages-submit-information-docs-js": () => import("./../../../src/pages/submit-information-docs.js" /* webpackChunkName: "component---src-pages-submit-information-docs-js" */)
}

